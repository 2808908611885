.video {
  position: relative;
  width: 100%;
}

.controls {
  position: absolute;
  top: 0;
  left: 0;
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #404040;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.videoContainer > .video {
  position: absolute;
  top: 0;
  left: 0;
}

.close {
  position: absolute;
  top: -17px;
  right: -17px;
}

.descriptionOnly {
  top: 0;
  border-radius: 10px;
}

.container {
  opacity: 0;
  animation: crescendo 0.2s ease-in-out 1 0.3s forwards;
}

@keyframes crescendo {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .container.descriptionOnly {
    top: 50%;
    border-radius: 10px;
  }

  .container.descriptionOnly > div {
    border-radius: 10px;
  }
}
