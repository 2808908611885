.input {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  height: 40px;
  align-items: center;
  padding: 0 12px;
  border: 1px solid #404040;
  background: #262626;
}

.input > input {
  flex: 1;
  background: transparent;
  border: none;
  color: #fff;
}

.input > input::placeholder {
  color: #8c8c8c;
}

.input > i {
  color: #8c8c8c;
  margin-right: 13px;
}

.input > input:focus {
  outline: none;
}

.container {
  width: 100%;
}

.error.input {
  border: 1px solid #ff1732;
}

.description {
  margin: 8px 12px 0;
  text-align: left;
  color: #8C8C8C;
}

.error, .error > input {
  color: #f2243c;
}

.disabled > input {
  color: #666666;
}

.disabled.input, .disabled.input > input {
  cursor: not-allowed;
}

.clear {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
}

.clear > img {
  width: 100%;
}

.focused {
  box-shadow: 0px 0px 4px rgba(77, 166, 255, 0.2);
  border: 1px solid #4da6ff;
}
