.badge {
  height: 24px;
  padding: 0 8px;
  background: #1a1a1a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid #404040;
}
