.zoomButtons {
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(3, 3, 3, 0) 0%,
    rgba(3, 3, 3, 0.2) 98.49%
  );
  height: 100px;
  display: flex;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: all;
}

.zoomButtons > .cancel {
  margin-right: 12px;
}
