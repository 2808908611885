.panel {
  background: #030303;
  box-shadow: 8px 0px 32px rgba(0, 0, 0, 0.15);
  height: 100%;
  transition: width 0.5s;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
}

.overlay {
  background: #030303;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}
