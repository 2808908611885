.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.checkbox {
  width: 16px;
  height: 16px;
  background: #404040;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.checked > .checkbox {
  background: linear-gradient(45deg, #0072FF 0%, #5BB4FF 100%);
}

.description {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #e6e6e6;
  flex: 1;
  text-align: left;
  margin-left: 12px;
}

.checked > .checkbox > i {
  opacity: 1;
}
