.top {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(26, 26, 26, 0.8) 0%,
    rgba(26, 26, 26, 0) 100%
  );
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.container {
  position: relative;
}
