
.controls {
  position: absolute;
  bottom: 32px;
  right: 20px;
}

.recenter {
  background: #1a1a1a;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
  border: none;
}

.zoom {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.zoom > button {
  background: #1a1a1a;
  width: 40px;
  height: 40px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.4);
  border: none;
}

.zoom > button:first-child {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.4);
}

.zoom > button:last-child {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.4);
}

.controls button:hover > .inner {
  background: #262626;
  border: 1px solid #404040;
}

.inner {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
