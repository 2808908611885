.container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 24px;
  height: 64px;
  background: transparent;
}

.background {
  background: #030303;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  flex: 1;
  text-align: left;
  padding-top: 3px;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

button.claim {
  width: 103px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  border-radius: 4px;
  margin-left: 24px;
  background: transparent;
}

button.claim:hover {
  border: #ffffff;
  background: #ffffff;
  color: #030303;
}

@media only screen and (max-width: 600px) {
  .right {
    display: none;
  }

  .logo {
    text-align: center;
  }

  .logo > img {
    height: 24px;
  }

  .container {
    height: 56px;
    padding: 0;
  }
}
