.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.top {
  padding: 12px 32px;
  height: 64px;
  border-bottom: 1px solid #262626;
  text-align: left;
}

.logo,
.logo > img {
  height: 100%;
}

.logo > img {
  height: 100%;
  max-width: 100%;
}

.menu > h2 {
  margin: 40px 32px 20px;
}

.items {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  flex: 1;
  overflow-y: auto;
}

.item {
  height: 48px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: transparent;
  border: 1px solid transparent;
  color: #8c8c8c;
}

.itemContainer + .itemContainer {
  margin-top: 4px;
}

.item > h3 {
  flex: 1;
}

.item:hover {
  background: #212121;
  border: 1px solid #212121;
  color: #ffffff;
}

.selected .item {
  background: #333333;
  border: 1px solid #404040;
  color: #ffffff;
}

.overflow {
  display: none;
}

.hasMenu .item:hover,
.hasMenu.active .item {
  padding-right: 13px;
}

.hasMenu .item:hover .overflow {
  display: block;
}

.hasMenu .item:hover .count,
.hasMenu.active .item .count {
  display: none;
}

.hasMenu.active .item .overflow {
  display: block;
}

.section {
  padding-bottom: 16px;
  border-bottom: 1px solid #333333;
}

.section:last-child {
  border-bottom: none;
}

.section > h5 {
  padding: 24px 18px 16px;
}
