.container {
  max-width: 400px;
  height: 64px;
  border: 1px solid #404040;
  display: flex;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.channel {
  flex: 1;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #ffffff;
}

.button {
  margin: 16px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  background: #262626;
  border-radius: 50%;
  border: none;
}

.time {
  margin: 24px 24px 24px 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #8c8c8c;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.position {
  background: #404040;
  transition: width .25s ease-in-out;
}
