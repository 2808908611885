.container {
  position: absolute;
  left: 100%;
  width: fit-content;
  background: #262626;
  border: 1px solid #404040;
  padding: 4px 0;
  border-radius: 8px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  margin-top: -1px;
}

.option {
  border: none;
  background: transparent;
  color: #ffffff;
  padding: 4px 16px;
}

.option:hover {
  background: #404040;
}
