.top {
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-bottom: 1px solid #262626;
  position: relative;
}

.badge {
  position: absolute;
  top: 16px;
  right: 16px;
}

.heading {
  margin: 25px 0 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heading > h1 {
  margin-right: 16px;
}

.location {
  color: #8c8c8c;
  margin: 0 0 24px 16px;
}

.bottom {
  background: #0d0d0d;
  border-top: 1px solid #262626;
  padding: 24px 16px;
  overflow: hidden;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
}

.open {
  animation-name: expand;
  animation-fill-mode: both;
}

.checkbox {
  flex: 1;
  margin-left: 16px;
}

.container {
  flex: 1;
  background: #030303;
  overflow-y: auto;
  min-height: 0;
  display: flex;
}

@keyframes expand {
  0% {
    height: 0;
  }
  100% {
    height: 55px;
    padding-top: 12px;
  }
}
