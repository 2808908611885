.push {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(245, 245, 245, 0.6);
  backdrop-filter: blur(72.2351px);
  border-radius: 18px;
  border: none;
  color: #000000;
  padding: 14px;
  width: 475px;
  box-shadow: 0px 10px 21px rgba(0, 0, 0, 0.09);
  animation: crescendo 0.2s ease-out 1;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.4px;
}

.logo > img {
  width: 27px;
  height: 27px;
  margin-right: 8px;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.4px;
}

.topLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  color: #8c8c8c;
}

.title {
  font-weight: 600;
  font-size: 19.9304px;
  line-height: 27px;
  letter-spacing: -0.318886px;
  color: #000000;
  text-transform: none;
  margin-bottom: 5px;
}

.address {
  font-weight: normal;
  font-size: 19.9304px;
  line-height: 24px;
  color: #000000;
  text-align: left;
}

@keyframes crescendo {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media only screen and (max-width: 850px) {
  .push {
    width: 330px;
  }

  .date {
    font-size: 12px;
    line-height: 14px;
  }

  .title {
    font-size: 14px;
    line-height: 18px;
  }

  .address {
    font-size: 14px;
    line-height: 17px;
  }

  .logo > img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .logo {
    font-size: 12px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .push {
    width: 95%;
    left: 2.5%;
  }
}
