.notif {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #1a1a1a;
  border: 1px solid #262626;
  border-radius: 10px;
  padding: 16px 20px;
  position: absolute;
  top: 16px;
  right: -18%;
  width: 407px;
  box-shadow: 0px 10px 21px rgba(0, 0, 0, 0.09);
}

.topLine {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #ffffff;
  width: 100%;
}

.date {
  flex: 1;
  text-align: left;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #ff1732;
  text-transform: none;
  margin-top: 4px;
}

.address {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #b3b3b3;
  margin-top: 4px;
}

@media only screen and (max-width: 850px) {
  .notif {
    width: 327px;
    top: -54px;
    right: -35px;
  }

  .topLine {
    font-size: 12px;
    line-height: 18px;
  }

  .title {
    font-size: 18px;
    line-height: 25px;
  }

  .address {
    font-size: 13px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .notif {
    width: 95%;
    right: 2.5%;
  }
}
