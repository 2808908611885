@font-face {
  font-family: "StyreneB";
  src: url("StyreneB-Light-Web.woff2") format("woff2"),
  url("StyreneB-Light-Web.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "StyreneB";
  src: url("StyreneB-Medium-Web.woff2") format("woff2"),
  url("StyreneB-Medium-Web.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "StyreneB";
  src: url("StyreneB-Regular-Web.woff2") format("woff2"),
  url("StyreneB-Regular-Web.woff") format("woff");
  font-weight: 500;
}

body {
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
  padding: 0;
  margin: 0;
  text-align: left;
}

h1 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.4px;
  text-align: left;
  font-weight: bold;
}

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.4px;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-transform: uppercase;
}

h5 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

h6 {
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
}

.body {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
}

.small {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
}

.regular {
  font-weight: normal;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}
