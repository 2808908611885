.container {
  background: none;
  border: none;
  cursor: pointer;
}

.logo {
  width: 40px;
  height: 40px;
  background: white;
}

.logo > img {
  width: 50%;
}

.button {
  background: #4285f4;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid #4285f4;
}

.buttonText {
  color: white;
}
