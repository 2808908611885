.marker.incident,
.marker.pin {
  width: 22px;
  height: 22px;

  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker.incident > div,
.marker.pin > div {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

.container.user {
  width: 8px;
  height: 8px;

  border-radius: 50%;
  background: #030303;
  opacity: 0.6;
  border: 1px solid rgba(3, 3, 3, 0.2);
}

.tooltip {
  background: rgba(38, 38, 38, 0.97);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.45);
  position: absolute;
  top: -38px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #ffffff;
  white-space: nowrap;
  padding: 6px 16px;
  border-radius: 4px;
  z-index: 3;
}

.container {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.container.incident,
.container.pin {
  min-width: 22px;
}

.container.incident {
  background: none;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  z-index: 1;
}

.pin > .tooltip {
  background: #4da6ff;
  color: #ffffff;
}

.container.userCount {
  min-width: 22px;
  width: fit-content;
  height: 44px;
  z-index: 2;
  white-space: nowrap;
  background: url("/icons/user-count-middle.svg");
  position: relative;
  top: 3px;
  transform: translate(-100%, -50%);
}

.container.userCount > p {
  top: 8px;
  position: relative;
}

.wrapper {
  position: relative;
}

.userCountLeft,
.userCountRight {
  position: absolute;
}

.userCountLeft > img,
.userCountRight > img {
  height: 44px;
  width: 15px;
}

.userCountLeft {
  top: 0;
  left: -15px;
}

.userCountRight {
  top: 0;
  left: 100%;
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
