div,
input,
button,
ul,
li,
textarea,
input,
i {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.app {
  text-align: center;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}
