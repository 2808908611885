.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay {
  background: rgb(3, 3, 3, 0.6);
  height: 100%;
  width: 100%;
}

.modal {
  background: rgb(3, 3, 3, 0.6);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
