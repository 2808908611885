.heading {
  background: #0d0d0d;
  box-shadow: inset 0px -1px 0px #262626;
  height: 64px;
}

.dropdown > button {
  background: transparent;
  border: none;
}

.locations {
  width: 300px;
}
