.input {
  width: 100%;
  background: #262626;
  border: 1px solid #404040;
  border-radius: 8px;
}

.input > textarea {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: #ffffff;
  padding: 10px 12px;
}

.input > textarea::placeholder {
  color: #8c8c8c;
}

.input > textarea:focus {
  outline: none;
}
