.small {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
}

.regular {
  font-weight: normal;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}
