button.react-calendar__navigation__arrow:disabled {
  color: #404040;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__tile {
  color: #eeecf1;
}

button.react-calendar__tile:disabled {
  color: #404040;
}

button.react-calendar__navigation__label {
  color: #eeecf1;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  font-weight: 500;
  max-width: 160px;
}

.react-calendar__navigation {
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.react-calendar__navigation__arrow {
  color: #8c8c8c;
  font-size: 16px;
  line-height: 24px;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  font-size: 12px;
  line-height: 16px;
  color: #eeecf1;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
}

.react-calendar__month-view__weekdays__weekday {
  margin-bottom: 24px;
}

button.react-calendar__month-view__days__day {
  width: 32px;
  height: 32px;
  margin: 4px 0;
}

button.react-calendar__month-view__days__day:not(:disabled):hover {
  background: url("/Circle.svg");
  background-repeat: no-repeat;
  background-position: center;
}

button.react-calendar__tile--active {
  background: url("/Blue-Circle.svg");
  background-repeat: no-repeat;
  background-position: center;
}

button.react-calendar__month-view__days__day > abbr {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  font-weight: 500;
}
