.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #030303;
  flex-direction: column;
}

.logo {
  margin-bottom: 40px;
}

.main {
  display: flex;
  flex-direction: column;
  background: #0d0d0d;
  border: 1px solid #262626;
  box-sizing: border-box;
  border-radius: 20px;
  width: 430px;
  height: 280px;
  text-align: center;
  padding: 40px 55px;
}

.text {
  text-align: center;
  margin-bottom: 25px;
}

.input {
  flex: 1;
}

.logout {
  background: none;
  border: none;
  color: #ffffff;
  margin-top: 8px;
}

.logout > p {
  text-align: center;
}

.logout:hover {
  text-decoration: underline;
}
