.container {
  position: relative;
  width: 100%;
}

.container h1,
.container h2,
.container h3 {
  font-family: StyreneB;
}

.map {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.overlay > h1,
.overlay > p {
  text-align: center;
  width: 50%;
  font-weight: normal;
}

.overlay > h1 {
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #b3b3b3;
}

.overlay > p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #ffffff;
  margin-top: 28px;
}

.heading {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

button.claim {
  background: #4da6ff;
  border-radius: 4px;
  height: 50px;
  padding: 0 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin-top: 35px;
  border: none;
}

.odometer {
  font-size: 56px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #ffffff;
  text-shadow: 0px 0px 20px #4da6ff;
  position: relative;
}

.number {
  display: none;
}

@media only screen and (max-width: 850px) {
  .overlay > h1,
  .odometer {
    font-size: 32px;
    line-height: 40px;
  }

  .overlay > h1 {
    width: 90%;
  }

  .overlay > p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 19px;
  }

  button.claim {
    margin-top: 25px;
  }

  .number {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    margin-top: 16px;
  }

  .heading {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 600px) {
  .overlay > p, .overlay > h1 {
    width: 95%;
  }
}
