.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  height: 40px;
  background: #262626;
  border: 1px solid #404040;
  border-radius: 8px;
  width: 100%;
}

.inputContainer {
  flex: 1;
}

.container div.input {
  padding: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
  border-top: 1px solid #404040;
  border-bottom: 1px solid #404040;
}

.container.focused div.input {
  border-top: 1px solid #4da6ff;
  border-bottom: 1px solid #4da6ff;
}

.icon {
  border: none;
  background: none;
  color: #666666;
  font-size: 15px;
  cursor: pointer;
}

.description {
  margin: 8px 12px 0;
  text-align: left;
}

.error {
  color: #f2243c;
}

.container.error,
.container.error div.input {
  border-color: #f2243c;
}
