.incident {
  width: 100%;
  padding: 12px 20px 16px;
  background: #1a1a1a;
  border: 1px solid #262626;
  border-radius: 10px;
  margin-top: 12px;
  cursor: pointer;
  color: #ffffff;
}

.incident:hover {
  background: #262626;
}

.incident:focus {
  outline: none;
}

.top {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time {
  flex: 1;
}

.creator {
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.25px;
  color: #b3b3b3;
}

.incident > h2 {
  margin-bottom: 4px;
}

.location {
  display: flex;
  color: #b3b3b3;
  justify-content: space-between;
}

.polygon {
  color: #b3b3b3;
}

.star:hover {
  background-color: #666666;
}
