.container {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 32px;
}

.container:first-child {
  margin-top: 32px;
}

.main {
  padding: 0 32px 32px 16px;
  text-align: left;
  flex: 1;
}

.separator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.ball {
  width: 12px;
  height: 12px;
  background: #404040;
  border-radius: 50%;
  margin: 4px 0;
}

.line {
  flex: 1;
  width: 4px;
  background: #1a1a1a;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #b3b3b3;
  margin-bottom: 4px;
}

.description {
  margin-left: 4px;
}

.verified > .separator > .ball {
  background: #4da6ff;
}

.verified > .main > .top {
  color: #4da6ff;
}

.text {
  margin-bottom: 16px;
}

.text:last-child {
  margin-bottom: 0;
}
