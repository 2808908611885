.container {
}

.label {
  color: #666666;
  margin-right: 8px;
}

.wrapper {
  display: inline-block;
  vertical-align: top;
}

.divider {
  height: 2px;
  background: #262626;
}
