.selected {
  background: #262626;
  border: 1px solid #404040;
  border-radius: 8px;
  width: 100%;
  padding: 0 12px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
}

.selectedItem {
  flex: 1;
}

.dropdown button:active,
.dropdown button:focus {
  outline: none;
  cursor: pointer;
}

.options {
  display: flex;
  flex-direction: column;
  background: #262626;
  border: 1px solid #404040;
  border-radius: 8px;
  overflow: auto;
  position: absolute;
  width: 100%;
  margin-top: 8px;
  padding: 8px 0;
  z-index: 2;
  max-height: 500px;
}

.option {
  padding: 13px 18px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  text-align: left;
  background: transparent;
}

.option:hover {
  background: #404040;
}

.dropdown {
  position: relative;
  width: 100%;
}
