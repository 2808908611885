.container {
  width: 100%;
  height: 100%;

  animation-fill-mode: both;
  animation-name: fadeIn;
  animation-delay: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
