.body {
  color: #8c8c8c;
  text-align: center;
  margin-top: 8px;
}

.container {
  max-width: 400px;
}

.button {
  width: 200px;
}

.image {
  height: 70px;
}

.bar {
  background: #666666;
  border-radius: 8px;
  height: 4px;
}

.error {
  color: #ff1732;
}
