.panel {
  width: 100%;
  margin-top: 115px;
  color: #030303;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.visual {
  width: 50%;
  position: relative;
}

.image {
  height: 640px;
  border-radius: 0 1000px 1000px 0;
  background-size: cover;
}

.visual:last-child > .image {
  border-radius: 1000px 0 0 1000px;
}

.info > h2 {
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.25px;
  margin-bottom: 26px;
  max-width: 480px;
}

.info > h2 > span {
  color: #b3b3b3;
}

.info > p {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.25px;
  max-width: 480px;
}

.info {
  margin-left: 9%;
  flex: 1;
}

.push {
  left: -18%;
  width: 50%;
  position: absolute;
  top: 16px;
}

.container {
  padding-bottom: 150px;
}

@media only screen and (max-width: 850px) {
  .image {
    height: 339px;
  }

  .info {
    margin-right: 32px;
  }

  .info > h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .info > p {
    font-size: 14px;
    line-height: 24px;
  }

  .push {
    left: -100px;
    width: 80%;
    top: -54px;
  }
}

@media only screen and (max-width: 600px) {
  .panel {
    flex-direction: column;
  }

  .visual {
    width: 100%;
  }

  .image {
    margin-right: 14px;
    height: 328px;
  }

  .info {
    margin-top: 37px;
    margin-left: 0;
    width: 80%;
  }

  .panel:last-child {
    flex-direction: column-reverse;
  }

  .push {
    left: 24px;
    width: 80%;
  }

  .panel:last-child > .visual > .image {
    margin-left: 14px;
    margin-right: 0;
  }
}
