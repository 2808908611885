.form {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: flex-start;
}

.labels {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
}

.description {
  color: #8c8c8c;
}

.input {
  width: 60%;
  padding: 0 32px 0 16px;
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
}
