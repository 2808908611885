.legal {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0;
}

.regular {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}
