.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
}

.input_group {
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.textarea {
  width: 100%;
  height: 80px;
  padding: 8px;
  margin: 4px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

.button {
  background-color: #b61515;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 8px 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 160px;
}

.button:hover {
  background-color: #ff3333;
}

.status {
  color: #b61515;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small_button {
  background-color: #b61515;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 2px 8px;
  margin: 0 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.small_button:hover {
  background-color: #ff3333;
}
