.marker.incident > div {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

.tooltip {
  background: rgba(38, 38, 38, 0.97);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.45);
  position: absolute;
  top: -38px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #ffffff;
  white-space: nowrap;
  padding: 6px 16px;
  border-radius: 4px;
  z-index: 3;
  display: none;
}

.container:hover .tooltip {
  display: block;
}

.container {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  background: none;
  padding: 36px;
  margin: 0;
  border: none;
  background: rgba(255, 23, 50, 0.2);
  border-radius: 50%;
  display: flex;
}

.incident {
  background: none;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: #ffffff;
}

.video {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  top: -160px;
  right: 70px;
}

.wrapper {
  position: relative;
}

.dot {
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.location {
  width: 6px;
  height: 6px;
  background: rgba(77, 166, 255, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .dot {
    width: 1px;
    height: 1px;
  }

  .location {
    width: 1px;
    height: 1px;
  }

  .info {
    display: block;
  }

  .video {
    right: auto;
    top: 0;
    -o-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
