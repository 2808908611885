.button {
  width: 100%;
  height: 56px;
  border: none;
  transition: background 150ms ease-in-out;
  padding: 0 16px;
  background: #262626;
  border-radius: 12px;
}

.button.on, .button:hover {
  background: #1a2633;
}

.check {
  width: 24px;
  height: 24px;
  border: 2px solid #4da6ff;
  border-radius: 50%;
  background: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 150ms ease-in-out;
}

.check > img {
  height: 20px;
}

.check.on {
  background: #4da6ff;
}

.name {
  color: #4da6ff;
}

.on .name {
  color: #ffffff;
}
