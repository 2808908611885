.container {
  background: #030303;
  width: 100%;
  position: relative;
}

.container > .limitWidth {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.container > h2 {
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.25px;
  font-weight: normal;
  color: #ffffff;
  max-width: 775px;
  width: 66%;
  padding: 155px 0 80px 0;
  margin: auto;
}

.container > h2 > span {
  color: #8c8c8c;
}

.panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 150px;
}

.visual {
  position: relative;
}

.panel > .visual,
.panel > .right {
  flex: 1;
  overflow: hidden;
  padding: 0 16px;
}

.fill > img {
  width: 100%;
}

.visual > img {
  max-width: 1183px;
}

.info {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info > p {
  max-width: 378px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.25px;
  color: #ffffff;
}

.mobile {
  display: flex;
  justify-content: center;
  position: relative;
}

.push {
  position: absolute;
  top: 100px;
  z-index: 2;
  width: 80%;
}

.push > div:first-child {
  color: #030303;
}

.mobile > img {
  width: 66%;
  z-index: 1;
  position: relative;
}

.overlay {
  position: absolute;
  background: #ffffff;
  height: 150px;
  width: 100%;
  bottom: 0;
}

.container > .overlay {
  max-width: none;
}

.sticky {
  align-items: flex-start;
  position: relative;
}

.sticky > .info {
  margin-top: 250px;
}

.fixed > .info {
  position: fixed;
  top: 0;
}

.fixed > .right,
.anchor > .right {
  margin-left: 50%;
}

.anchor > .info {
  position: absolute;
  bottom: 520px;
}

.web {
  border: 1px solid #333333;
}

.right > .mobile {
  margin-top: 124px;
}

.extension {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  left: calc(50% - 31px);
  margin-top: -210px;
  z-index: 2;
}

.ball > div {
  background: rgba(77, 166, 255, 0.8);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.ball {
  width: 62px;
  height: 62px;
  background: rgba(77, 166, 255, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots {
  height: 230px;
  width: 2px;
  background: url("/icons/dotted-line.svg");
  margin-top: -11px;
}

@media only screen and (max-width: 850px) {
  .container > h2 {
    font-size: 32px;
    line-height: 40px;
    padding: 68px 0 44px 0;
    width: 50%;
  }

  .extension {
    display: none;
  }

  .panel {
    width: 90%;
    margin-bottom: 0;
  }

  .visual > img {
    width: 100%;
  }

  .info > p {
    text-align: center;
  }

  .sticky {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sticky > .info {
    margin-top: 67px;
    margin-bottom: 65px;
    width: 90%;
  }

  .sticky > .info > p {
    font-size: 14px;
    line-height: 24px;
  }

  .fixed > .info {
    position: relative;
  }

  .fixed > .right {
    margin-left: 0;
  }

  .mobile {
    max-width: 500px;
    margin: auto;
  }

  .right > .visual:first-child {
    margin-bottom: 78px;
  }

  .right > .visual:last-child > img {
    width: 75%;
  }

  .mobileReverse {
    flex-direction: column-reverse;
    margin: auto;
  }

  .mobileReverse > .info {
    margin: 43px 0;
    width: 90%;
  }

  .mobileReverse > .info > p {
    font-size: 14px;
    line-height: 24px;
  }

  .mobileReverse > .visual > img {
    width: 75%;
  }

  .mobile > img {
    z-index: 1;
  }

  .panel > .visual {
    padding: 0;
    overflow: visible;
  }
}

@media only screen and (max-width: 600px) {
  .container > h2 {
    width: 80%;
  }

  .push {
    width: 100%;
    left: auto;
  }
}
