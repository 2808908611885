.body {
  color: #8c8c8c;
  text-align: center;
  margin-top: 8px;
}

.container {
  max-width: 400px;
}

.image {
  width: 80px;
}
