.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #1cb33e;
  border: 1px solid rgba(3, 3, 3, 0.05);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 3px;
  height: 16px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon, .icon > img {
  height: 100%;
}

.icon {
  display: flex;
  align-items: center;
}
