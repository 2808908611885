.banner {
  padding: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #ffffff;
  background: #030303;
  border-radius: 4px 4px 0 0;

  position: fixed;
  bottom: 0;
  max-width: 408px;
  margin: auto;
  left: calc(50% - 204px);

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: 2;
}

.banner > img {
  margin-right: 12px;
}

@media only screen and (max-width: 600px) {
  .banner {
    width: 90%;
    margin: 0 5%;
    left: auto;
    text-align: left;
    padding: 5px 20px;
  }
}
