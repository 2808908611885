.button {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  color: #8c8c8c;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:not(:disabled):hover {
  background: #1a1a1a;
  color: #ffffff;
}

.button.active {
  background: #404040;
  color: #ffffff;
}

.button:disabled {
  cursor: auto;
}
