.container {
  width: 100%;
  height: 140px;
  background: #030303;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container > img {
  width: 10%;
}

@media only screen and (max-width: 850px) {
  .container > img {
    width: 40%;
    max-width: 150px;
  }
}
