.flex {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.column {
  flex-direction: column;
}

.fill {
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.button {
  cursor: pointer;
}
