.container {
  background: #1a1a1a;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  max-width: 400px;
  min-width: 336px;
  overflow: hidden;
  color: #ffffff;
}

.text {
  padding: 24px 32px;
  border-bottom: solid 1px #262626;
}

.description {
  margin-top: 8px;
  color: #b3b3b3;
}

.actions {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.confirm {
  margin-left: 16px;
}
