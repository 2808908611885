.callout {
  font-size: 32px;
  line-height: 40px;
  font-weight: normal;
}

.subtext {
  color: #b3b3b3;
}

.stat {
  background: #212121;
  border-radius: 16px;
  margin-right: 32px;
  width: 248px;
  height: 248px;
}

.stat:last-child {
  margin-right: 0;
}
