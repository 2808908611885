.container {
  background: #030303;
  opacity: 0.9;
  backdrop-filter: blur(4px);
  padding: 12px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #ff1732;
  text-align: left;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #ffffff;
  text-align: left;
  text-transform: none;
}

.address {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #b3b3b3;
  text-align: left;
}
