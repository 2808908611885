.body {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
}

.body.regular {
  font-weight: normal;
}

.body.bold {
  font-weight: 600;
}
