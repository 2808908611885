.panel {
  background: #1a1a1a;
  box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.35);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #262626;
  position: relative;
  min-width: 300px;
}

.main {
  background: #030303;
  overflow-x: auto;
}
