.heading {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 115px 0 100px 0;
}

.heading > p {
  width: 33%;
  max-width: 333px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #030303;
}

.container {
  top: -150px;
  position: relative;
  background: #ffffff;
}

.logos {
  margin: auto;
  width: 83%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.partner {
  flex: 1;
}

.partner > img {
  height: 30px;
}

@media only screen and (max-width: 850px) {
  .container {
    top: 0;
  }

  .heading > p {
    font-size: 14px;
    line-height: 24px;
    width: 80%;
  }

  .heading {
    padding: 50px 0;
  }

  .logos {
    flex-direction: column;
    width: 80%;
  }

  .partner {
    margin-bottom: 65px;
    max-width: 200px;
  }

  .partner > img {
    width: 100%;
    height: auto;
  }

  .partner:last-child {
    margin-bottom: 94px;
  }
}
