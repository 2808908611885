.container {
  background: #1a1a1a;
  border-radius: 16px;
  max-width: 600px;
  position: relative;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.headng {
  text-align: left;
}

.submit {
  width: 150px;
}

.info {
  color: #8c8c8c;
  max-width: 350px;
}
