.bottom {
  padding: 12px 12px 32px 12px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
}

.search {
  border-bottom: 1px solid #262626;
  height: 64px;
  padding: 12px;
  background: #0d0d0d;
}

.flex_row {
  display: flex;
  align-items: center;
}

.menu {
  width: 40%;
  background: #1a1a1a;
  box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.35);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #262626;
  position: relative;
}

.feed {
  flex: 1;
  height: 100%;
  background: #030303;
  box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
}

.locations {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
}

.incidents {
  flex: 1;
  overflow-y: auto;
  padding: 0 12px;
  min-height: 0;
}
