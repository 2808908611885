.mute {
  background: rgba(3, 3, 3, 0.4);
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.mute:hover {
  background: rgba(3, 3, 3, 0.6);
}
