.container {
  height: 100%;
  width: 100%;
  background: #212121;
}

.video {
  position: fixed;
  width: 100%;
  top: 75px;
  left: 50px;
  z-index: 2;
}
