.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #030303;
  flex-direction: column;
}

.logo {
  margin-bottom: 40px;
}

.error {
  color: #ff0000;
  margin-bottom: 20px;
}
