.top {
  height: 140px;
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  box-shadow: 0px 1px 0px #262626;
}

.close {
  margin-bottom: 25px;
}

.top > h1 {
  margin-left: 16px;
}

.form {
  width: 100%;
  margin: 40px 0 20px;
  display: flex;
  align-items: flex-start;
}

.labels {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
}

.description {
  color: #8c8c8c;
}

.input {
  width: 60%;
  padding: 0 32px 0 16px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  background: #030303;
}

div.creationForm {
  flex: none;
  overflow-y: unset;
}
