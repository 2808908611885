.hidden {
  visibility: hidden;
}

.popover {
  position: absolute;
  right: 0;
  z-index: 3;
  border: 1px solid #e4e4e4;
  background-color: rgb(55, 55, 55);
}

.popover_hidden {
  position: absolute;
  right: 0;
  visibility: hidden;
}

.flex_col {
  display: flex;
  flex-direction: column;
}

.form_group {
  z-index: 5;
}

.form_container {
  z-index: 5;
  margin: 10px 0;
}

.filter_button {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  color: white;
  background-color: #1a1a1a;
  margin: 0 10px;
  height: 40px;
}

.filter_button_activate {
  border: 1px solid #1a1a1a;
  border-radius: 8px;
  color: #1a1a1a;
  background-color: white;
  margin: 0 10px;
  height: 40px;
}
