.header {
  color: #8c8c8c;
  height: 64px;
}

.row {
  color: #ffffff;
  box-shadow: inset 0px -1px 0px #1a1a1a;
  height: 64px;
}

.header,
.row > div {
  margin-left: 32px;
}

.plus {
  width: 10px;
  height: 10px;
  opacity: 0.6;
}
