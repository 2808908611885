.container {
  width: 100%;
  color: #030303;
  max-width: 580px;
  margin: 0 auto 125px auto;
}

.container > h2 {
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #030303;
  width: 66%;
  max-width: 378px;
  font-weight: normal;
  margin: 0 auto 48px auto;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.input {
  background: white;
  border: 1px solid #e6e6e6;
}

.input > input {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #0d0d0d;
}

.inputContainer + .inputContainer {
  margin-left: 20px;
}

.row + .row {
  margin-top: 10px;
}

.submit {
  margin-top: 20px;
  width: 100%;
  background: #4da6ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #ffffff;
  height: 48px;
  border: none;
}

.submit:disabled {
  background: #1CB33E;
}

.container > p {
  width: 100%;
  text-align: center;
  margin-top: 27px;
}

@media only screen and (max-width: 850px) {
  .container > h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 37px;
  }

  .row {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }

  .inputContainer + .inputContainer {
    margin-left: 0;
    margin-top: 10px;
  }

  .submit {
    width: 90%;
    margin: 24px auto 0 auto;
  }

  .container {
    margin: 0 auto 59px auto;
  }
}
