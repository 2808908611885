.container {
  display: flex;
  flex-direction: column;
  background: #0d0d0d;
  border: 1px solid #262626;
  box-sizing: border-box;
  border-radius: 20px;
  width: 430px;
  height: 290px;
  text-align: center;
  padding: 40px 55px;
}

.text {
  text-align: center;
  margin-bottom: 8px;
}

.subtext {
  margin-bottom: 25px;
  color: #8C8C8C;
  text-align: center;
}

.input {
  flex: 1;
}

.button {
  width: 120px;
  margin: auto;
}
