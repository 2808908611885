.button {
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  color: #ffffff;
  position: relative;
}

.button.block {
  width: 100%;
}

.primary {
  background: linear-gradient(13.47deg, #0072ff 0%, #5bb4ff 100%);
  border: none;
}

.primary:hover {
  background: linear-gradient(0deg, #0072ff 0%, #5bb4ff 100%);
}

.secondary {
  background: #1c2833;
  border: 1px solid #243442;
  border-radius: 35px;
}

.secondary:hover {
  border: 1px solid #364d63;
}

.subtle {
  background: #262626;
  border: 1px solid #404040;
  border-radius: 27px;
}

.subtle:hover {
  border: 1px solid #595959;
}

.subtleReversed {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  border-radius: 27px;
  color: #333333;
  border: none;
}

.subtleReversed:hover {
  background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
}

.disabled {
  background: #262626;
  border: 1px solid #262626;
  border-radius: 27px;
  cursor: not-allowed;
  color: #666666;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.07);
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small > .icon {
  margin-right: 4px;
}

.medium > .icon {
  margin-right: 8px;
}

.large > .icon {
  margin-right: 12px;
}

.large {
  height: 48px;
}

.small {
  height: 28px;
  padding: 0 12px;
}

.cancel,
.overflowMenu {
  font-size: 13px;
  background: #262626;
  border: 1px solid #404040;
  border-radius: 27px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.overflowMenu:hover {
  background: #404040;
}

.overflowMenu.active {
  background: #404040;
  border: 1px solid #666666;
}

.cancel:hover > .tooltip {
  visibility: visible;
  opacity: 1;
}

.cancelIcon,
.menuIcon {
  width: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tooltip {
  position: absolute;
  left: 44px;
  background: #262626;
  border-radius: 4px;
  padding: 4px 8px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s ease-in-out;
}
