.button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
}

.button:disabled .left {
  fill: #404040;
}

.left {
  transform: rotate(180deg);
}
