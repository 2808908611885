.suggestion {
  width: 100%;
  padding: 13px 18px;
  background: #1a1a1a;
  color: #ffffff;
  border: none;
  list-style: none;
  margin: 0;
  text-align: left;
  cursor: pointer;
}

.suggestion + .suggestion {
  border-top: 1px solid #404040;
}

.suggestion:hover {
  background: #262626;
}

.input {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  height: 40px;
  align-items: center;
  padding: 0 14px;
  border: 1px solid #404040;
  background: #262626;
  color: #fff;
}

.input:focus {
  outline: none;
}

.input:disabled,
.input > input:disabled {
  color: #666666;
  -webkit-text-fill-color: #666666;
}

.input > input {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #262626;
  border: none;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  font-weight: normal;
}

.suggestionsContainer {
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  padding: 0;
}

.suggestionsList {
  padding: 0;
  margin: 0;
  z-index: 4;
}

.suggestionsContainerOpen {
  border: 1px solid #404040;
}

.container {
  position: relative;
}

.suggestionHighlighted {
  background: #262626;
}
