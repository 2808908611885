.popup {
  background: #1cb33e;
  border: 1px solid rgba(3, 3, 3, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 6px 12px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 12px auto;
}

.popup > img {
  margin-right: 4px;
}

.popupShown {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  justify-content: center;
  text-align: center;
}
