.container {
  display: flex;
  flex-direction: column;
  background: #0d0d0d;
  border: 1px solid #262626;
  box-sizing: border-box;
  border-radius: 20px;
  width: 430px;
  height: 280px;
  text-align: center;
  padding: 55px;
}

.text {
  text-align: center;
  margin-bottom: 25px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
}

.button {
  width: 120px;
  margin: auto;
}

.input {
  border-radius: 4px;
  height: 40px;
  width: 40px;
}

.input + .input {
  margin-left: 16px;
}

.usePassword {
  font-size: 12px;
  line-height: 1.33;
  color: #22c9ff;
  padding-top:15px;
  cursor: pointer;
  background: none;
  border: none;
}

.usePassword:hover {
  background: none;
  border: none;
}
